import { setTimeout } from "core-js";

const toggleWelcomeGamification= () => {
  const btn = document.querySelector('#welcome-gamification-toggle')
  const title = document.querySelector('#welcome-gamification-title')
  if (btn){
    btn.addEventListener("click", (event) => {
      event.preventDefault();
      if (title.innerText == "Ma participation"){
        title.innerText = "Notre participation"
        btn.setAttribute('title', "Ma participation")
        toggleScoreTeam()
      }else{
        title.innerText = "Ma participation"
        btn.setAttribute('title', "Notre participation")
        toggleScoreUser()
      }
    })
  }
};

const toggleScoreTeam = () => {
  const scores = document.querySelectorAll(".welcome-gamification-score")
  for (let i = 0; i < scores.length; i++) {
    const score = scores[i];
    let result = ""
    if (parseInt(score.dataset.scoreTeam) < 2) {
      result = "1er"
    } else {
      result = score.dataset.scoreTeam + "ème"
    }
    setTimeout(() => {
      score.innerText = result
    }, 500);
    MyFadeFunction(score)
  }
}

const toggleScoreUser = () => {
  const scores = document.querySelectorAll(".welcome-gamification-score")
  for (let i = 0; i < scores.length; i++) {
    const score = scores[i];
    let result = ""
    if (score.dataset.scoreUser == "-") {
      result = "-"
    } else if (parseInt(score.dataset.scoreUser) < 2) {
      result = "1er"
    } else {
      result = score.dataset.scoreUser + "ème"
    }
    setTimeout(() => {
      score.innerText = result
    }, 500);
    MyFadeFunction(score)
  }
}

const toggleWelcomeIdeaction= () => {
  const btn = document.querySelector('#welcome-ideaction-toggle')
  const title = document.querySelector('#welcome-ideaction-title')
  if (btn) {
    btn.addEventListener("click", (event) => {
      event.preventDefault();
      if (title.innerText == "Mon idéaction"){
        title.innerText = "Notre idéaction"
        btn.setAttribute('title', "Mon idéaction")
        toggleBarIdeaction();
      }else{
        title.innerText = "Mon idéaction"
        btn.setAttribute('title', "Notre idéaction")
        toggleBarMy();
      }
    })
  }
};

const toggleBarIdeaction = () => {
  const progressbars = document.querySelectorAll(".toggle-progress")
  for (let i = 0; i < progressbars.length; i++){
    const bar = progressbars[i]
    let plurelize = ""
    if (bar.dataset.text == "idée") {
      if (parseInt(bar.dataset.ideactionNbr) > 1){
        plurelize = "s"
      }
    }
    bar.innerText = bar.dataset.ideactionNbr + " " + bar.dataset.text + plurelize
    bar.setAttribute('style', bar.dataset.widthIdeaction)
  }
}

const toggleBarMy = () => {
  const progressbars = document.querySelectorAll(".toggle-progress")
  for (let i = 0; i < progressbars.length; i++){
    const bar = progressbars[i]
    let plurelize = ""
    if (bar.dataset.text == "idée") {
      if (parseInt(bar.dataset.nbr) > 1){
        plurelize = "s"
      }
    }
    bar.innerText = bar.dataset.nbr + " " + bar.dataset.text + plurelize
    bar.setAttribute('style', bar.dataset.width)
  }
}

function MyFadeFunction(item) {
  item.style.opacity = 0
  setTimeout(() => {
    item.style.opacity = 1
  }, 500);
}

export{toggleWelcomeGamification, toggleWelcomeIdeaction}