import $ from 'jquery';
import 'datatables.net-bs4';

const initDatatableOrganization = () => {
  // add for turbolinks
  var dataTable = null
  if (document.getElementById('table-datatables')) {
    $(document).ready(function () {
      dataTable = $('#table-datatables').DataTable(
        {
          "stateSave": true, // add for turbolinks
          "responsive": true,
          "language": {
            "decimal": "",
            "emptyTable": "Pas de data",
            "info": "Afficher _START_ à _END_ sur _TOTAL_ lignes",
            "infoEmpty": "Afficher 0 à 0 sur 0 ligne",
            "infoFiltered": "(filtré de _MAX_ lignes au total)",
            "infoPostFix": "",
            "thousands": "",
            "lengthMenu": "_MENU_",
            "loadingRecords": '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
            "processing": "Traitement...",
            "search": "",
            "zeroRecords": "Aucun résultat correspondants trouvés",
            "paginate": {
              "first": "First",
              "last": "Last",
              "next": "Suivant",
              "previous": "Précédent"
            }
          }
        }
      );
    });
  }
  if (document.getElementById('table-organization')) {
    const orga_id = document.getElementById('table-organization').dataset.organizationId
    $(document).ready(function () {
      dataTable = $('#table-organization').DataTable(
        {
          ajax: `/api/internal/organizations/${orga_id}/organization_memberships`,
          columns: [
            {
              data: { role: 'role', id: 'id', role_id: 'role_id' },
              render: function (data) {
                return '<a href="" data-toggle="modal" data-target="#roleOrganizationModal" data-id= "' + data.id + '" data-role="role_' + data.role_id + '" data-role-id="' + data.role_id + '">' + data.role + '</a>';
              }
            },
            {
              data: { first_name: 'first_name', onboarding: 'onboarding' },
              render: function (data) {
                if (data.onboarding) {
                  return data.first_name
                } else {
                  return '<div class="text-muted">' + data.first_name + '</div>'
                }
              }
            },
            {
              data: { last_name: 'last_name', onboarding: 'onboarding' },
              render: function (data) {
                if (data.onboarding) {
                  return data.last_name
                } else {
                  return '<div class="text-muted">' + data.last_name + '</div>'
                }
              }
            },
            {
              data: { email: 'email', onboarding: 'onboarding', invite: 'invite' },
              render: function (data) {
                if (data.onboarding) {
                  return '<a href="mailto:' + data.email + '", class="mail-link">' + data.email + '</a>'
                } else {
                  return '<a href="mailto:' + data.email + '", class="mail-link">' + data.email + '</a> <span class="text-muted">(' + data.invite + ')</span>'
                }
              }
            },
            {
              data: { tag_name: "team_categories.0.tags.0.name" },
              render: function (data) {
                if (data.team_categories.length > 0) {
                  const tags = data.team_categories[0].tags
                  var result = ''
                  if (tags.length != 0) {
                    for (let index = 0; index < tags.length; index++) {
                      const element = tags[index];
                      result += '<span class=mr-2>' + element.name + '</span>'
                    }
                    return result
                  } else {
                    return data.no_team
                  }
                } else {
                  return data.no_team
                }
              }
            },
            {
              data: { id: 'id', admin: 'admin', locked_at: 'locked_at' },
              render: function (data) {
                var result = '<div class="d-flex justify-content-around align-items-center">'
                result += '<a href="/organization_memberships/' + data.id + '/edit_user" class="text-muted"><i class="fa-solid fa-pen"></i></a>'
                if (!data.admin) {
                  if (data.locked_at) {
                    result += '<a href="/organization_memberships/' + data.id + '/toggle_lock" data-method="patch" class="text-warning"><i class="fa-solid fa-lock"></i></a>'
                    result += '<a href="/organization_memberships/' + data.id + '" data-method="delete" data-confirm="Supprimer ce compte et l\'ensemble de ses éléments (idées/appels/soutiens)" class="text-danger"><i class="fa-solid fa-trash" title="cliquez pour supprimer"></i></a>'
                  } else {
                    result += '<a href="/organization_memberships/' + data.id + '/toggle_lock" data-method="patch" class="text-muted"><i class="fa-solid fa-lock"></i></a>'
                  }
                }
                result += '</div>'
                return result
              }
            }
          ],
          "stateSave": true, // add for turbolinks
          "responsive": true,
          "language": {
            "decimal": "",
            "emptyTable": "Pas de data",
            "info": "Afficher _START_ à _END_ sur _TOTAL_ lignes",
            "infoEmpty": "Afficher 0 à 0 sur 0 ligne",
            "infoFiltered": "(filtré de _MAX_ lignes au total)",
            "infoPostFix": "",
            "thousands": "",
            "lengthMenu": "_MENU_",
            "loadingRecords": '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
            "processing": "Traitement...",
            "search": "",
            "zeroRecords": "Aucun résultat correspondants trouvés",
            "paginate": {
              "first": "First",
              "last": "Last",
              "next": "Suivant",
              "previous": "Précédent"
            }
          }
        }
      );
    });
  }
  if (document.getElementById('table-ideaction')) {
    const orga_id = document.getElementById('table-ideaction').dataset.organizationId
    const ideaction_id = document.getElementById('table-ideaction').dataset.ideactionId
    $(document).ready(function () {
      dataTable = $('#table-ideaction').DataTable(
        {
          ajax: `/api/internal/organizations/${orga_id}/ideactions/${ideaction_id}/ideaction_memberships`,
          columns: [
            {
              data: { role: 'role', role_id: 'role_id', full_name: "full_name", id: "id", referent: "referent", supervisor: "supervisor" },
              render: function (data) {
                let result = ''
                if (data.referent) {
                  result += '<i class="fa-solid fa-crown mr-3 text-warning" title="référent"></i>'
                }
                result += '<a href="" data-toggle="modal" data-target="#roleUserModal" data-fullname="' + data.full_name + '" data-id= "' + data.id + '" data-role="role_' + data.role_id + '" data-role-id="' + data.role_id + '" data-referent= "' + data.referent + '">' + data.role + '</a>'
                return result
              }
            },
            {
              data: { first_name: 'first_name', onboarding: 'onboarding' },
              render: function (data) {
                if (data.onboarding) {
                  return data.first_name
                } else {
                  return '<div class="text-muted">' + data.first_name + '</div>'
                }
              }
            },
            {
              data: { last_name: 'last_name', onboarding: 'onboarding' },
              render: function (data) {
                if (data.onboarding) {
                  return data.last_name
                } else {
                  return '<div class="text-muted">' + data.last_name + '</div>'
                }
              }
            },
            {
              data: { tag_name: "team_categories.0.tags.0.name" },
              render: function (data) {
                if (data.team_categories.length > 0) {
                  const tags = data.team_categories[0].tags
                  var result = ''
                  if (tags.length != 0) {
                    for (let index = 0; index < tags.length; index++) {
                      const element = tags[index];
                      result += '<span class=mr-2>' + element.name + '</span>'
                    }
                    return result
                  } else {
                    return data.no_team
                  }
                } else {
                  return data.no_team
                }
              }
            },
            {
              data: { email: 'email' },
              render: function (data) {
                return '<a href="mailto:' + data.email + '", class="mail-link">' + data.email + '</a>'
              }
            },
            {
              data: { created_at: 'created_at', onboarding: 'onboarding' },
              render: function (data) {
                if (data.onboarding) {
                  return data.created_at
                } else {
                  return '<div class="text-muted">' + data.created_at + '</div>'
                }
              }
            },
            {
              data: { onboarding: 'onboarding', onboarding_text: 'onboarding_text' },
              render: function (data) {
                return data.onboarding_text
              }
            },
            {
              data: { notification_text: 'notification_text', notification: 'notification', id: 'id' },
              render: function (data) {
                if (data.notification) {
                  return '<a href="/ideaction_memberships/' + data.id + '/toggle_notification" data-method="patch" data-confirm="Voulez vous desactiver la notification?">' + data.notification_text + '</a>'
                } else {
                  return '<a href="/ideaction_memberships/' + data.id + '/toggle_notification" data-method="patch" data-confirm="Voulez vous activer la notification?">' + data.notification_text + '</a>'
                }
              }
            },
            {
              data: { id: 'id', locked_at: 'locked_at' },
              render: function (data) {
                var result = '<div class="d-flex justify-content-around align-items-center">'
                if (data.locked_at) {
                  result += '<a href="/ideaction_memberships/' + data.id + '/toggle_lock" data-method="patch" class="text-warning"><i class="fa-solid fa-lock"></i></a>'
                  result += '<a href="/ideaction_memberships/' + data.id + '" data-method="delete" data-confirm="Supprimer ce compte et l\'ensemble de ses éléments (idées/appels/soutiens)" class="text-danger"><i class="fa-solid fa-trash" title="cliquez pour supprimer"></i></a>'
                } else {
                  result += '<a href="/ideaction_memberships/' + data.id + '/toggle_lock" data-method="patch" class="text-muted"><i class="fa-solid fa-lock-open"></i></a>'
                }
                result += '</div>'
                return result
              }
            }
          ],
          "stateSave": true, // add for turbolinks
          "responsive": true,
          "language": {
            "decimal": "",
            "emptyTable": "Pas de data",
            "info": "Afficher _START_ à _END_ sur _TOTAL_ lignes",
            "infoEmpty": "Afficher 0 à 0 sur 0 ligne",
            "infoFiltered": "(filtré de _MAX_ lignes au total)",
            "infoPostFix": "",
            "thousands": "",
            "lengthMenu": "_MENU_",
            "loadingRecords": '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
            "processing": "Traitement...",
            "search": "",
            "zeroRecords": "Aucun résultat correspondants trouvés",
            "paginate": {
              "first": "First",
              "last": "Last",
              "next": "Suivant",
              "previous": "Précédent"
            }
          }
        }
      );
    });
  }
  if (document.getElementById('table-loging')) {
    const orga_id = document.getElementById('table-loging').dataset.organizationId
    $(document).ready(function () {
      dataTable = $('#table-loging').DataTable(
        {
          ajax: `/organizations/${orga_id}/logings.json`,
          columns: [
            {
              data: { created_at: 'created_at' },
              render: function (data) {
                return data.created_at;
              }
            },
            {
              data: { mail: 'mail' },
              render: function (data) {
                return data.mail;
              }
            },
            {
              data: { title: 'title', },
              render: function (data) {
                return data.title
              }
            },
            {
              data: { description: 'description', },
              render: function (data) {
                return data.description
              }
            },
            {
              data: { ideaction_name: 'ideaction' },
              render: function (data) {
                return data.ideaction_name
              }
            }
          ],
          "stateSave": true, // add for turbolinks
          "responsive": true,
          "language": {
            "decimal": "",
            "emptyTable": "Pas de data",
            "info": "Afficher _START_ à _END_ sur _TOTAL_ lignes",
            "infoEmpty": "Afficher 0 à 0 sur 0 ligne",
            "infoFiltered": "(filtré de _MAX_ lignes au total)",
            "infoPostFix": "",
            "thousands": "",
            "lengthMenu": "_MENU_",
            "loadingRecords": '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
            "processing": "Traitement...",
            "search": "",
            "zeroRecords": "Aucun résultat correspondants trouvés",
            "paginate": {
              "first": "First",
              "last": "Last",
              "next": "Suivant",
              "previous": "Précédent"
            }
          }
        }
      );
    });
  }

  // add for turbolinks
  document.addEventListener("turbolinks:before-cache", function () {
    if (dataTable !== null) {
      dataTable.destroy()
      dataTable = null
    }
  })
}

export { initDatatableOrganization }