const resetValidityDateIdeaction = () => {
  const btn = document.getElementById('reset_date')
  const input = document.getElementById('inputValidityDate')
  if (input && btn) {
    btn.addEventListener('click', e => {
      e.preventDefault()
      input.value = ""
      btn.classList.add('d-none')
    })
    input.addEventListener('change', e =>{
      btn.classList.remove('d-none')
    })
  }
  
}


export{resetValidityDateIdeaction}