const removeValidateForm = () => {
  const list = ['ideaction_proposals_deadline',
    'ideaction_proposals_validation_step',
    'ideaction_proposals_notification_new',
    'ideaction_risks_notification_new',
    'ideaction_risks_notification_revival',
    'ideaction_ideaction_notification_revival',
    'ideaction_risks_deadline',
    'ideaction_membership_role',
    'ideaction_proposal_default_description',
    'ideaction_proposal_limit_votes_number']
  for (let i = 0; i < list.length; i++) {
    const id = list[i];
    removeValidate(id);
  }
}

const removeValidate = (id) => {
  const input = document.getElementById(id)
  if (input) {
    input.classList.remove('is-valid')
  }
}

export { removeValidateForm }