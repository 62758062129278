const updatePurposeModal = () => {
  $('#purposeUpdateModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('purposeId')
    var name = button.attr('data-purpose-name')
    var modal = $(this)
    modal.find('#update_purpose').attr("action", "/purposes/" + id)
    document.querySelector("#update_purpose_name").value = name
  })
};

const updatePurposeModalClose = () => {
  $('#updatePurposeModalSubmit').click(function() {
    $('#purposeUpdateModal').modal('hide');
  });
}

export{updatePurposeModal, updatePurposeModalClose}