import flatpickr from "flatpickr";

const initFlatpickt = () => {
  flatpickr(".validity_date", {
    "locale": {
      "firstDayOfWeek" :1,
      dateFormat: "d-m-Y"
    }
  })

  const weekshort = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  const weeklong = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const monthshort = ['Jan', 'Fév', 'Мar', 'Аvr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'];
  const monthlong = ['Janvier', 'Février', 'Mars', 'Аvril', 'Мai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Оctobre', 'Novembre', 'Décembre'];


  flatpickr(".datepicker", {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: weekshort,
        longhand: weeklong,         
      }, 
      months: {
        shorthand: monthshort,
        longhand: monthlong,
      }
    },
    minDate: "today",
    dateFormat: "d-m-Y"
  });

  flatpickr(".datepickerCompany", {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: weekshort,
        longhand: weeklong,         
      }, 
      months: {
        shorthand: monthshort,
        longhand: monthlong,
      }
    },
    dateFormat: "d-m-Y"
  });

  if (document.querySelector('.datepickerIdeaction')){
    const validityDate = document.getElementById('inputValidityDate')
    let maxDateOrga = validityDate.dataset.date
    if (maxDateOrga == ""){
      maxDateOrga = null
    }
    flatpickr(".datepickerIdeaction", {
      "locale": {
        "firstDayOfWeek": 1,
        weekdays: {
          shorthand: weekshort,
          longhand: weeklong,         
        }, 
        months: {
          shorthand: monthshort,
          longhand: monthlong,
        }
      },
      maxDate: maxDateOrga,
      minDate: "today"
    });

  }

  let evaluatedDate;
  if (document.querySelector('.datepickerAffected')){
    evaluatedDate = document.getElementById('proposal_started_at').dataset.evaluated;
    let startDate = document.getElementById('proposal_started_at').value.split("-").reverse().join('-');
    if (startDate == ""){
      startDate = null
    }
    flatpickr(".datepickerAffected", {
      "locale": {
        "firstDayOfWeek": 1,
        weekdays: {
          shorthand: weekshort,
          longhand: weeklong,         
        }, 
        months: {
          shorthand: monthshort,
          longhand: monthlong,
        }
      },
      maxDate: evaluatedDate,
      dateFormat: "d-m-Y"
    });
    
    
    flatpickr(".datepickerMilestone", {
      "locale": {
        "firstDayOfWeek": 1,
        weekdays: {
          shorthand: weekshort,
          longhand: weeklong,         
        }, 
        months: {
          shorthand: monthshort,
          longhand: monthlong,
        }
      },
      minDate: startDate,
      maxDate: evaluatedDate,
      dateFormat: "d-m-Y"
    });
  }

}

export{initFlatpickt}