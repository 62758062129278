const updateTeamModal = () => {
  $('#teamUpdateModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('teamId')
    var name = button.attr('data-team-name')
    var orgaId = button.data('orgaId')
    var modal = $(this)
    document.querySelector("#update_team_name").value = name
    modal.find('#update_team').attr("action", "/organizations/" + orgaId + "/teams/" + id)
  })
};

const updateTeamModalClose = () => {
  $('#updateTeamModalSubmit').click(function() {
    $('#teamUpdateModal').modal('hide');
  });
}

export{updateTeamModal, updateTeamModalClose}