const updateTargetModal = () => {
  $('#targetUpdateModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('targetId')
    var name = button.attr('data-target-name')
    var modal = $(this)
    modal.find('#update_target').attr("action", "/targets/" + id)
    document.querySelector("#update_target_name").value = name
  })
};

const updateTargetModalClose = () => {
  $('#updateTargetModalSubmit').click(function() {
    $('#targetUpdateModal').modal('hide');
  });
}

export{updateTargetModal, updateTargetModalClose}