const inviteUsersModal = () => {
  // auto open modal
  const div = document.getElementById('openInviteUsersModal')
  if (div) {
    $('#inviteUserModal').modal('show');
  }

  // toggle disabled submit btn invite user
  const submitUser = document.getElementById('submit_invite_users')
  const inputUser = document.querySelector("#new_ideaction_membership ul")
  if (inputUser) {
    const observer = new MutationObserver(() => {
      const nbr = document.querySelectorAll("#new_ideaction_membership .select2-selection__choice").length;
      if (nbr === 0) {
        submitUser.setAttribute("disabled", "disabled");
      } else {
        submitUser.removeAttribute("disabled");
      }
    });

    observer.observe(inputUser, { childList: true, subtree: true });
  }

  update_btn(submitUser);

  // add composition team tags
  const addTeamTag = document.getElementById('add-composition-team-tags');
  if (addTeamTag) {
    addTeamTag.addEventListener('click', () => {
      // get composition name and id
      const teamCategories = document.querySelectorAll('.composition-team-categories');
      const composition_name = [];
      const composition_id = [];
      teamCategories.forEach(e => {
        const option = e.options[e.selectedIndex];
        composition_name.push(option.innerHTML);
        composition_id.push(option.value);
        e.selectedIndex = 0;
        addTeamTag.blur();
      });
      document.getElementById('composition-team-tags-show').innerHTML = "<span class='text-secondary'>Faire une combinaison</span>";

      // add composition card and id in input
      const joinIds = composition_id.join('_');
      const joinNames = nameCompositionTeamTags(composition_id, composition_name).join(' <strong>Et</strong> ');
      if (!document.getElementById(joinIds)) {
        const compositionListTeamTags = document.getElementById('composition-list-team-tags');
        if (joinNames != "") {
          // get number user of combinaison
          const url = `/api/internal/organizations/${addTeamTag.dataset.organization}/ideactions/${addTeamTag.dataset.ideaction}/ideaction_memberships/team_tags_users?composition=/${joinIds}&regrouping=${addTeamTag.dataset.regrouping}`;
          fetch(url, { headers: { accept: "application/json" } })
            .then(response => response.json())
            .then((data) => {
              // add team tag card
              compositionListTeamTags.insertAdjacentHTML("beforeend", compositionTeamTagCard(joinIds, joinNames, data.data.length));
              document.getElementById('composition-list-no-team').style.display = "none";
              // add input composition team tags
              const inputCompostionTeamTags = document.getElementById('input-compostion-team-tags');
              inputCompostionTeamTags.value = inputCompostionTeamTags.value + `/${joinIds}`;
              toggleSubmitBtnInviteTeam();
            });
        }
      }

      setTimeout(() => {
        // delete composition
        const teamTagNameRemoves = document.querySelectorAll('.team-tag-name-remove');
        teamTagNameRemoves.forEach(e => {
          e.addEventListener('click', () => {
            // remove input composition team tags
            const inputCompostionTeamTags = document.getElementById('input-compostion-team-tags');
            inputCompostionTeamTags.value = inputCompostionTeamTags.value.replace(`/${e.dataset.id}`, "");
            // remove card
            const composition = document.getElementById(e.dataset.id);
            composition.remove();
            toggleSubmitBtnInviteTeam();
          });
        });
      }, 500);
    });
  }

  // Pre show selection team tag
  const teamCategories = document.querySelectorAll('.composition-team-categories');
  const teamCategor = document.getElementById('composition-team-tags-show');
  if (teamCategories) {
    teamCategories.forEach(e => {
      e.addEventListener('change', () => {
        const composition_name = [];
        const composition_id = [];
        teamCategories.forEach(e => {
          const option = e.options[e.selectedIndex];
          composition_name.push(option.innerHTML);
          composition_id.push(option.value);
        });
        const joinNames = nameCompositionTeamTags(composition_id, composition_name).join(' <strong>Et</strong> ');
        if (joinNames) {
          teamCategor.innerHTML = joinNames;
        } else {
          teamCategor.innerHTML = "<span class='text-secondary'>Faire une combinaison</span>";
        }
      });
    });
  }

  // def name composition team tags
  const nameCompositionTeamTags = (id, name) => {
    let nameJoin = [];
    let flag_id = 0;
    id.forEach(e => {
      if (e != "0") {
        nameJoin.push(name[flag_id]);
      }
      flag_id = 1 + flag_id;
    });
    return nameJoin;
  };

  // team tag card
  const compositionTeamTagCard = (id, name, qts) => {
    let contentHTML = "";
    contentHTML += `<div id='${id}' class='composition-list-team-tag'>`;
    contentHTML += `<div class="team-tag-name">${name} (${qts} ideacteurs)</div>`;
    contentHTML += `<div class="team-tag-name-remove" data-id="${id}"><i class="fa-solid fa-xmark"></i></div>`;
    contentHTML += `</div'>`;
    return contentHTML;
  };

  // toggle disabled submit btn invite team
  const toggleSubmitBtnInviteTeam = () => {
    const submitTeam = document.getElementById('submit_invite_teams');
    const inputTeam = document.getElementById('input-compostion-team-tags');
    const nbr = inputTeam.value.length;
    if (nbr == 0) {
      submitTeam.setAttribute("disabled", "disabled");
    } else {
      submitTeam.removeAttribute("disabled");
    }
    update_btn(submitTeam);
  };

  // organization add users
  const submitOrganization = document.getElementById("submit_invite_organization");
  update_btn(submitOrganization);
}

const check_status = (organization, ideaction, user) => {
  const url = `/record_job_status.json?organization=${organization}&ideaction=${ideaction}&user=${user}&type=ideaction_membership`;
  const bar = document.getElementById('job-progress');
  fetch(url, { headers: { accept: "application/json" } })
    .then(response => response.json())
    .then((data) => {
      if (data.record_job == 0) {
        fetch(`/organizations/${organization}/ideactions/${ideaction}/update_proposals_score_max`);
        location.reload();
      }
    });
};

const update_btn = (btn) => {
  if (btn) {
    btn.addEventListener('click', () => {
      setInterval(() => {
        check_status(btn.dataset.organization, btn.dataset.ideaction, btn.dataset.user);
      }, 2000);
      setTimeout(() => {
        document.getElementById("ideaction_membership_user").setAttribute("disabled", "disabled");
        document.getElementById("add_user_team").setAttribute("disabled", "disabled");
        document.getElementById("submit_invite_organization").classList.add("disabled");
      }, 200);
      setTimeout(() => {
        btn.setAttribute("disabled", "disabled");
        btn.value = "Loading...";
        btn.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...';
      }, 250);
    });
  }
}

export { inviteUsersModal }
