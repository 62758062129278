const editTeamCategory = () => {
  const names = document.querySelectorAll('.team-category-name')
  names.forEach(element => {
    element.addEventListener('click',()=>{
      document.getElementById(`team-category-edit-${element.dataset.id}`).style.display = "unset"
      element.style.display = "none"
    })
  });
  const cancels = document.querySelectorAll('.team-category-edit-cancel')
  cancels.forEach(element => {
    element.addEventListener('click',()=>{
      document.getElementById(`team-category-edit-${element.dataset.id}`).style.display = "none"
      document.getElementById(`team-category-${element.dataset.id}`).style.display = "unset"
    })
  })
}

export {editTeamCategory}