const editTemplate = () => {
  $('#editTemplateModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('id')
    var name = button.data('name')
    document.getElementById('editTemplateModalTitle').innerText = name
    getTemplate(id)
  })
}

const getTemplate = (id) => {
  fetch(`/templates/${id}`, { headers: { accept: 'application/json' } })
        .then(response => response.json())
        .then((data) => {
          setTemplate(data.template)
          setPurposes(data.purposes)
          setTargets(data.targets)
        });
}

const setTemplate = (template) => {
  document.querySelector('.edit_template #template_template_category').value = `${template.template_category_id}`
  document.querySelector('.edit_template #template_goal').value = template.goal
  document.querySelector('.edit_template #template_name').value = template.name
  document.querySelector('.edit_template').action = `/templates/${template.id}`
  document.getElementById('new_template_purpose').action = `/templates/${template.id}/template_purposes`
  document.getElementById('new_template_target').action = `/templates/${template.id}/template_targets`
}

const setPurposes = (purposes) => {
  const purposeList = document.getElementById('purposeList')
  purposeList.innerHTML = ""
  purposes.forEach(purpose => {
    purposeList.insertAdjacentHTML("beforeend", purposeCard(purpose))
  });
}

const purposeCard = (purpose) => {
  const contentHTML = `<div id="purpose-${purpose.id}" class="purpose-card">
                        <div class="purpose-card-sub">
                          <div class="purpose-card-title">
                            ${purpose.name}
                          </div>
                          <div class="purpose-card-actions">
                            <a href="/template_purposes/${purpose.id}" data-method="delete" data-remote="true">
                              <i class="fa-solid fa-trash-alt"></i>
                            </a>
                          </div>
                        </div>
                      </div>`
  return contentHTML
}

const setTargets = (targets) => {
  const targetList = document.getElementById('targetList')
  targetList.innerHTML = ""
  targets.forEach(target => {
    targetList.insertAdjacentHTML("beforeend", targetCard(target))
  });
}

const targetCard = (target) => {
  const contentHTML = `<div id="target-${target.id}" class="target-card">
                        <div class="target-card-sub">
                          <div class="target-card-title">
                            ${target.name}
                          </div>
                          <div class="target-card-actions">
                            <a href="/template_targets/${target.id}" data-method="delete" data-remote="true">
                              <i class="fa-solid fa-trash-alt"></i>
                            </a>
                          </div>
                        </div>
                      </div>`
  return contentHTML
}

export {editTemplate}