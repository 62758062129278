const toggleNotifications = () => {
  const btnOpen = document.querySelector('#toggle-notifications')
  const notifications = document.querySelector('.notifications')
  const allSeen = document.querySelector('#notification_all_seen')
  if (btnOpen) {
    btnOpen.addEventListener("click", (event) => {
      event.preventDefault();
      if (notifications.classList.contains("active")){
        notifications.classList.remove("active")
      } else {
        notifications.classList.add("active")
      }
    })
    const btnClose = document.querySelector('.notifications-close')
    btnClose.addEventListener("click", (event) => {
      event.preventDefault();
      notifications.classList.remove("active")
    })
    allSeen.addEventListener("click", () =>{
      const allNotification = document.querySelectorAll('.notification')
      allNotification.forEach(element => {
        element.classList.remove("not-seen")
      });
      document.querySelector('#notification-counter').innerHTML=''
    })
  }
}

export {toggleNotifications}