const valForm = (button,input) => {
  input.addEventListener("keyup", ()=> {
    if (input.value.length != 0) {
      if(button.attributes.getNamedItem("disabled") != null){
        button.attributes.removeNamedItem("disabled")
      }
    } else {
      button.setAttribute("disabled", "disabled")
    }
  })
};

export{valForm}