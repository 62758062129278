const addIdeaction = () => {
  // init const
  const inputGoal = document.getElementById('inputGoal')
  const inputName = document.getElementById('inputName')
  const inputPicture = document.getElementById('logo-input')
  const inputValidityDate = document.getElementById('inputValidityDate')

  // btn for next and previus modal
  const btnGoalNext = document.getElementById('ideaction_goal_next')
  const btnGoalPrevious = document.getElementById('ideaction_goal_previous')
  const btnParameterPrevious = document.getElementById('ideaction_parameter_previous')
  const btnParameterNext = document.getElementById('ideaction_parameter_next')
  const btnParameterLitePrevious = document.getElementById('ideaction_parameter_lite_previous')
  const btnParameterLiteNext = document.getElementById('ideaction_parameter_lite_next')
  const btnNamePrevious = document.getElementById('ideaction_name_previous')
  const btnTemplateNext = document.getElementById('template_next')
  const btnParameterMore = document.getElementById('ideaction_parameter_more')

  // Form
  const ideactionGoal = document.getElementById('ideaction_goal')
  const ideactionPicture = document.getElementById('ideaction_picture')
  const ideactionName = document.getElementById('ideaction_name')
  const ideactionValidityDate = document.getElementById('ideaction_validity_date')

  const btnFormSubmit = document.getElementById('ideaction_submit')
  const ideactionForm = document.getElementById('new_ideaction')

  // temp no lite parameter
  if (btnNamePrevious) {
    btnNamePrevious.dataset.ParameterMore = 1
    btnGoalNext.dataset.ParameterMore = 1
  }

  // selected less config ideaction
  const selectTypeIdeaction = document.getElementsByName('ideactionType')
  const inputTypeIdeaction = document.getElementById('inputTypeIdeaction')
  if (selectTypeIdeaction){
    selectTypeIdeaction.forEach(element => {
      element.addEventListener('click', (event)=>{
        inputTypeIdeaction.value = event.target.value
      })
    });
  }

  if (btnFormSubmit && inputName) {
    inputName.addEventListener('keyup', () => {
      if (inputName.value.length != 0 && inputGoal.value.length != 0) {
        btnGoalNext.classList.remove('disabled')
      } else {
        btnGoalNext.classList.add('disabled')
      }
    })
  }

  if (btnTemplateNext ){
    btnTemplateNext.addEventListener('click', (event) =>{
      event.preventDefault();
      inputGoal.value = document.getElementById('template_next').dataset.templateGoal
      btnGoalNext.classList.remove('disabled')
      inputName.value = document.getElementById('template_next').dataset.templateName
      btnFormSubmit.classList.remove('disabled')
      $('#templateModal').modal('hide');
      setTimeout(() => {$('#newIdeactionGoalModal').modal('show')}, 500);
    })
  }

  // run if inputGoal and btn goal next
  if (inputGoal && btnGoalNext) {
    // check input is not empty
    inputGoal.addEventListener('keyup', () => {
      if (inputGoal.value.length != 0 && inputName.value.length != 0) {
        btnGoalNext.classList.remove('disabled')
      } else {
        btnGoalNext.classList.add('disabled')
      }
    })

    // next goal modal
    btnGoalNext.addEventListener('click', (event) =>{
      event.preventDefault();
      ideactionGoal.value = inputGoal.value
      ideactionPicture.files = inputPicture.files
      ideactionValidityDate.value = inputValidityDate.value
      ideactionName.value = inputName.value
      $('#newIdeactionGoalModal').modal('hide');
      if (1 == 1) {
        setTimeout(() => {$('#newIdeactionParameterModal').modal('show');}, 500);
      }else{
        setTimeout(() => {$('#newIdeactionParameterLiteModal').modal('show');}, 500);
      }
    })

    // previous goal modal
    if (btnGoalPrevious) {
      btnGoalPrevious.addEventListener('click', (event) => {
        event.preventDefault();
        $('#newIdeactionGoalModal').modal('hide');
        setTimeout(() => {$('#templateModal').modal('show');}, 500);
      })
    }
    
    // previous parameter modal
    btnParameterPrevious.addEventListener('click', (event) => {
      event.preventDefault();
      $('#newIdeactionParameterModal').modal('hide');
      setTimeout(() => {$('#newIdeactionGoalModal').modal('show');}, 500);
    })

    // previous parameter lite modal
    btnParameterLitePrevious.addEventListener('click', (event) => {
      event.preventDefault();
      $('#newIdeactionParameterLiteModal').modal('hide');
      setTimeout(() => {$('#newIdeactionGoalModal').modal('show');}, 500);
    })
    
    // next parameter lite modal
    btnParameterLiteNext.addEventListener('click', (event) => {
      event.preventDefault();
      $('#newIdeactionParameterLiteModal').modal('hide');
      setTimeout(() => {$('#newIdeactionNameModal').modal('show');}, 500);
    })

    // more parameter modal
    btnParameterMore.addEventListener('click', (event) => {
      event.preventDefault();
      btnNamePrevious.dataset.ParameterMore = 1
      btnGoalNext.dataset.ParameterMore = 1
      inputTypeIdeaction.value = 0
      $('#newIdeactionParameterLiteModal').modal('hide');
      setTimeout(() => {$('#newIdeactionParameterModal').modal('show');}, 500);
    })
    
    // submit form
    btnFormSubmit.addEventListener('click', (event) => {
      event.preventDefault();
      ideactionForm.submit()
    })
  }
}

export{addIdeaction}