const riskNewProposalModal = () => {
  const newProposalModal = document.getElementById('open_new_proposal_modal')
  const proposalModal = document.getElementById('newProposalFormModal')
  const purposeModal = document.getElementById('purpose_modal')
  if (proposalModal && newProposalModal) {
    if (purposeModal) {
      $(function() {
        $("#purpose_modal").modal('show');
      });
    }else{
      $(function() {
        $("#newProposalFormModal").modal('show');
      });
    }
  }
}

export{ riskNewProposalModal}