const clearValidityDate = () => {
  const btn = document.getElementById('validity_date_delete')
  if (btn) {
    btn.addEventListener("click", ()=> {
      const input = document.getElementById('organization_validity_date')
      input.value = ""
    })
  }
}

export{clearValidityDate}