const arrowsDownScroll = () => {
  // listen to click on arrows_down
  if (document.querySelector('#ad1')) {
    const arrows_down = document.querySelector('#ad1');
    arrows_down.addEventListener("click", (event) => {
    // scroll down to footer
    document.querySelector('.srow').scrollIntoView({
    behavior: 'smooth'
  });
  });
  };

  if (document.querySelector('#ad2')) {
    const arrows_down = document.querySelector('#ad2');
    arrows_down.addEventListener("click", (event) => {
    // scroll down to footer
    document.querySelector('.trow').scrollIntoView({
    behavior: 'smooth'
  });
  });
  }

};

const navScroll = () => {
  window.onscroll = function() {
    if (document.getElementById("mainNav")) {
      if (window.pageYOffset > 100) {
        document.getElementById("mainNav").classList.add("home-menu-shrink");
      } else {
        document.getElementById("mainNav").classList.remove("home-menu-shrink");
      }
    }
  }
};

const menuResponsive = () => {
  const btnOpen = document.querySelector('#home-menu-responsive-open')
  if (btnOpen) {
    const menu = document.querySelector('.home-menu-responsive')
    btnOpen.addEventListener("click", (event) => {
      event.preventDefault();
      menu.classList.add("active")
    })
    const btnClose = document.querySelector('.home-menu-responsive-close')
    btnClose.addEventListener("click", (event) => {
      event.preventDefault();
      menu.classList.remove("active")
    })
    const btnLinks = document.querySelectorAll('.home-menu-responsive-link')
    btnLinks.forEach(element => {
      element.addEventListener("click", (event) => {
        console.log('click btn menu');
        
        menu.classList.remove("active")
      })
    });
    for (let index = 0; index < btnLinks.length; index++) {
      const btnlink = btnLinks[index];
      btnlink.addEventListener("click", (event) => {
        console.log('click btn menu');
        
        menu.classList.remove("active")
      })
      
    }
  }
}

export { arrowsDownScroll,navScroll, menuResponsive };
