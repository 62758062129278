const iconList = () => {
  const icons = document.querySelectorAll(".icon-choice")
  if (icons) {
    for (let i = 0; i < icons.length; i++) {
      const icon = icons[i];
      icon.addEventListener("click", () => {
        for (let j = 0; j < icons.length; j++) {
          const element = icons[j];
          element.classList.remove("active")
        }
        icon.classList.add("active")
      })
    }
  }

}

export {iconList}