

const filePreview = () => {
  // we select the photo input
  const input = document.getElementById('file-input');
  const input_video = document.getElementById('file-input-video')
  if (input || input_video) {
    // we add a listener to know when a new picture is uploaded
    input.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input);
    })
  }
  if (input_video) {
    // we add a listener to know when a new picture is uploaded
    input_video.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input_video);
    })
  }
}

const displayPreview = (input) => {
  if (input.files && input.files[0]) {
    const result = input.value.split("\\")
    document.getElementById('file-preview').innerText = result[result.length - 1];
  }
}

export { filePreview };