const riskPurposeSelect = () => {
  const purposes = document.querySelectorAll(".risk_purpose")
  const purposeSelect = document.getElementById("risk_purpose")
  if (purposes) {
    purposes.forEach(purpose => {
      purpose.addEventListener("click", (event) =>{
        $('#purpose_modal').modal('hide');

        if (event.target.dataset.id != "null"){
          purposeSelect.value = event.target.dataset.id;
        }
        setTimeout(() => {$('#new_risk_form').modal('show')}, 450)
      })
    })
  }
}

export {riskPurposeSelect}