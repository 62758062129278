// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require('es6-promise').polyfill();
require('isomorphic-fetch');
require("trix");
require("@rails/actiontext");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import { initDatatableOrganization } from "../plugins/datatables";
import { initFlatpickt } from "../plugins/flatpickr";
import { initSelect2, initSelect2Multi } from "../plugins/select2";
window.$ = jQuery;
import "controllers" //stimulusJS

// myJS
import { previewLogoOnFileSelect } from "../components/logo_preview";
import { destroyOrganizationModal } from "../components/validateDeleteOrganization";
import { updateTeamModal, updateTeamModalClose } from "../components/updateTeam";
import { clearValidityDate } from "../components/clear_validity_date";
import { checkEmail } from "../components/check_email";
import { importUsers } from "../components/import_users";
import { addIdeaction } from "../components/add_ideaction";
import { photoPreview } from "../components/photo_preview";
import { toggleSwitch } from "../components/toggle_switch";
import { destroyIdeactionModal } from "../components/validateDeleteideaction";
import { addTeamUserModal } from "../components/add_team_user";
import { removeValidateForm } from "../components/remove_validate_form";
import { updateTargetModal, updateTargetModalClose } from "../components/updateTarget";
import { updatePurposeModal, updatePurposeModalClose } from "../components/updatePurpose";
import { typedJsBanner } from "../plugins/typedjs";
import { arrowsDownScroll, menuResponsive, navScroll } from "../pages/home";
import { trixInit } from "../plugins/trix_init";
import { iconList } from "../components/icon_list";
import { filePreview } from "../components/file_preview";
import { purposeSelect } from "../components/purpose_select";
import { valForm } from "../components/validateForm";
import { pilotForm, planForm, affectFrom, evaluatedForm, addMilestone, evaluatedDirectForm } from "../components/trackingStepForms"
import { homeAdminModal } from "../components/home_admin_modal"
import { riskPurposeSelect } from "../components/risk_purpose_select";
import { updateRoleIdeactionModal } from "../components/updateRoleIdeaction";
import { toggleWelcomeGamification, toggleWelcomeIdeaction } from "../components/gamifications";
import { toggleNotifications } from "../components/toggle_notifications";
import { updateRoleOrganizationModal } from "../components/updateRoleOrganization";
import { pictureRiskModal } from "../components/picture_risks_modal";
import { riskNewProposalModal } from "../components/risk_new_proposal_modal";
import { newIdeactionModal } from "../components/auto_open_modal";
import "../components/smooth_scroll"
import { popupNewProposal } from "../components/popup_new_proposal";
import { inviteToAction } from "../components/invit_to_action";
import { riskCorrectionToggle } from "../components/risk_correction_toggle";
import { templateModal } from "../components/template_modal";
import { editTemplate } from "../components/edit_template";
import { editTemplateCategory } from "../components/edit_template_category";
import { inviteUsersModal } from "../components/invite_users_modal";
import { openTargetModal } from "../components/open_target_modal";
import { openValidateProposalModal } from "../components/open_validate_proposal_modal";
import { initPopover } from "../plugins/popover";
import { closeMsteamsModal } from "../components/close_msteams_modal";
import { inviteToInvolvementModal } from "../components/open_invite_to_involvement_modal";
import { openShowRule } from "../components/open_show_rule";
import { prospectModal } from "../components/prospect_modal";
import { proposalWarningProposalMosal } from "../components/proposal_warning_modal";
import { ratingProposalModal } from "../components/rating_proposal_modal";
import { proposalWarnings } from "../components/proposal_warnings";
import { teamRelaunchModal } from "../components/team_relaunch_modal";
import { unsupportReferent } from "../components/unsupport_referent";
import { usersRelaunchModal } from "../components/users_relaunch_modal";
import { proposalTagCell } from "../components/proposal_tag_cell";
import { resetValidityDateIdeaction } from "../components/reset_validity_date_ideaction";
import { openModal } from "../components/open_modal";
import { modalNewTeamTag } from "../components/modal_new_team_tag";
import { modalEditTeamTag } from "../components/modal_edit_team_tag";
import { editTeamCategory } from "../components/edit_team_category";
import { modalEditRegrouping } from "../components/modal_edit_regrouping";
import { regroupingAddUsersModal } from "../components/regrouping_add_users_modal";
import { regroupingRelaunchModal } from "../components/regrouping_relaunch_modal";


document.addEventListener('turbolinks:load', () => {
  $('.select2-container').remove() //remove the select2 container DOM
  $('.select2-input').select2(); // Select2 will just reinit the DOM

  // plugins
  initDatatableOrganization();
  initFlatpickt();
  initSelect2();
  initSelect2Multi();
  typedJsBanner();
  initPopover();

  // myJS
  previewLogoOnFileSelect();
  photoPreview();
  destroyOrganizationModal();
  destroyIdeactionModal();
  updateTeamModal();
  updateTeamModalClose();
  clearValidityDate();
  checkEmail();
  importUsers();
  addIdeaction();
  toggleSwitch();
  addTeamUserModal();
  removeValidateForm();
  updateTargetModal();
  updateTargetModalClose();
  updatePurposeModal();
  updatePurposeModalClose();
  arrowsDownScroll();
  navScroll();
  menuResponsive();
  trixInit();
  iconList();
  filePreview();
  purposeSelect();
  riskPurposeSelect();
  if (document.getElementById("btnValForm")) {
    const btn = document.getElementById("btnValForm");
    const input = document.getElementById("inputValForm");
    valForm(btn, input);
  }
  if (document.getElementById("btnValForm2")) {
    const btn = document.getElementById("btnValForm2");
    const input = document.getElementById("inputValForm2");
    valForm(btn, input);
  }
  pilotForm();
  planForm();
  affectFrom();
  evaluatedForm();
  addMilestone();
  evaluatedDirectForm();
  homeAdminModal();
  updateRoleIdeactionModal();
  toggleWelcomeGamification();
  toggleWelcomeIdeaction();
  toggleNotifications();
  updateRoleOrganizationModal();
  pictureRiskModal();
  riskNewProposalModal();
  newIdeactionModal();
  popupNewProposal();
  inviteToAction();
  riskCorrectionToggle();
  templateModal();
  editTemplate();
  editTemplateCategory();
  inviteUsersModal();
  openTargetModal();
  openValidateProposalModal();
  closeMsteamsModal();
  inviteToInvolvementModal();
  openShowRule();
  prospectModal();
  proposalWarningProposalMosal();
  ratingProposalModal();
  proposalWarnings();
  teamRelaunchModal();
  unsupportReferent();
  usersRelaunchModal();
  proposalTagCell();
  resetValidityDateIdeaction();
  openModal('updateUserNotificationModal');
  modalNewTeamTag();
  modalEditTeamTag();
  editTeamCategory();
  modalEditRegrouping();
  regroupingAddUsersModal();
  regroupingRelaunchModal();
});