const updateRoleOrganizationModal = () => {
  $('#roleOrganizationModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('id')
    var role = button.data('role')
    var roleId = button.data('roleId')
    var modal = $(this)
    modal.find('#update_organization_membership').attr("action", "/organization_memberships/" + id)
    modal.find('#organizationMembershipRole option').prop('selected', false)
    modal.find(`#${role}`).prop('selected', true)
    initListRule();
    selectListRule(parseInt(roleId, 10));
  })
  const inputSelect = document.getElementById('organizationMembershipRole')
  if (inputSelect) {
    inputSelect.addEventListener('change', () => {
      initListRule();
      const optRole = inputSelect.options[inputSelect.selectedIndex]
      selectListRule(parseInt(optRole.value, 10));
    })
  }
};
const initListRule = () => {
  const listRules = document.querySelectorAll('.listRule')
  listRules.forEach(listRule => {
    listRule.classList.add("not_select")
    listRule.querySelector("i").classList.remove("fa-circle-check")
    listRule.querySelector("i").classList.add("fa-circle-xmark")
  });
}

const selectListRule = (element) => {
  switch (element) {
    case 0:
      addListRule(document.querySelectorAll('.listRule1'));
      break;
    case 1:
      addListRule(document.querySelectorAll('.listRule1'));
      addListRule(document.querySelectorAll('.listRule2'));
      break;
    case 2:
      addListRule(document.querySelectorAll('.listRule1'));
      addListRule(document.querySelectorAll('.listRule2'));
      addListRule(document.querySelectorAll('.listRule3'));
      break;
  }
}

const addListRule = (elements) => {
  elements.forEach(element => {
    element.classList.remove("not_select")
    element.querySelector("i").classList.add("fa-circle-check")
    element.querySelector("i").classList.remove("fa-circle-xmark")
  });
}

export { updateRoleOrganizationModal }