const toggleSwitch = () => {
  // toggle switch work with toggle_switch parcial : add toggle-switch for active
  const toggleSwitchList = document.getElementsByClassName('toggle-switch');
  if (toggleSwitchList.length != 0){
    const checkboxList = []
    for (let i = 0; i < toggleSwitchList.length; i++) {
      const element = toggleSwitchList[i];
      checkboxList.push(element.id)
    }

    // syncro toggle and input value
    for (let i = 0; i < checkboxList.length; i++) {
      const element = checkboxList[i];
      const inputSwith = document.getElementById(element)
      const inputValue = document.getElementById(element + '_value')
      inputSwith.addEventListener('click',()=>{
        if ( inputValue.value == "0"){
          inputValue.value = "1"
        }else{
          inputValue.value = "0"
        }
      })
    }
  }
}

export{toggleSwitch}