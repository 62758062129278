const modalEditTeamTag = () => {
  $('#editTeamTagFormModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var tag = button.data('tag')
    var tagName = button.data('tagName')
    var organization_id = button.data('organization')

    // update link for delete team tag
    var deleteLink = document.getElementById("edit-team-tag-destroy")
    deleteLink.href = `/organizations/${organization_id}/team_tags/${tag}`

    // udpate title modal
    setTitle(tagName, tag)

    // reset display 
    resetEdit();

    // get list users
    getTeamTag(tag, organization_id);

    // update form
    setTeamTagForm(tag, tagName, organization_id);
  })
  const title = document.getElementById('editTeamTagFormModalTitle')
  if (title) {
    title.addEventListener('click', () => {
      document.getElementById('editTeamTagForm').style.display = "unset"
      document.getElementById('editTeamTagFormModalTitle').style.display = "none"
    })
  }
  const cancel = document.getElementById('team-tag-edit-cancel')
  if (cancel) {
    cancel.addEventListener('click', () => {
      document.getElementById('editTeamTagForm').style.display = "none"
      document.getElementById('editTeamTagFormModalTitle').style.display = "unset"
    })
  }
}

const resetEdit = () => {
  document.getElementById('editTeamTagForm').style.display = "none"
  document.getElementById('editTeamTagFormModalTitle').style.display = "unset"
  document.getElementById('edit-team-tag-users').innerHTML = '<div class="d-flex justify-content-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>'

}

const setTeamTagForm = (tag, tagName, organization_id) => {
  const input = document.getElementById('edit_team_tag_name')
  input.value = tagName;
  const form = document.getElementById('edit_team_tag')
  form.action = `/organizations/${organization_id}/team_tags/${tag}`
}

const getTeamTag = (tag, organization_id) => {
  fetch(`/organizations/${organization_id}/team_tags/${tag}`, { headers: { accept: 'application/json' } })
    .then(response => response.json())
    .then((data) => {
      setUsers(data.users, organization_id)
    });
}

const setTitle = (team_tag, tag) => {
  const title = document.getElementById('editTeamTagFormModalTitle')
  title.innerHTML = team_tag
  document.getElementById("membership_team_tag_team_tag").value = tag
}

const setUsers = (users, organization_id) => {
  const html_users = document.getElementById('edit-team-tag-users')
  html_users.innerHTML = ""
  if (users.length == 0) {
    html_users.insertAdjacentHTML('beforeend', `<div>Aucun idéacteur</div>`);
  }
  users.forEach(user => {
    html_users.insertAdjacentHTML('beforeend', `<div id="membership-${user.membership_id}" class="edit-team-tag-user"><div>${user.full_name}</div><div class="cta"><a href="/organizations/${organization_id}/membership_team_tags/${user.membership_id}" data-method="delete" data-confirm="Voulez vous retirer l'ideacteur du groupe?"><i class="fa-solid fa-minus"></i></a></div></div>`);
  });
}

export { modalEditTeamTag }