const ratingProposalModal = () => {
  const ratingModal = document.getElementById('ratingProposalModal')
  if (ratingModal){
    // init modal
    $('#ratingProposalModal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget) // Button that triggered the modal
      var organization = button.data('organization')
      var ideaction = button.data('ideaction')
      var id = button.data('id')
      var title = button.data('title')
      var modal = $(this)
      modal.find('#ratingProposalName').text(title)
      const form = document.getElementById('new_proposal_rating')
      form.action = `/organizations/${organization}/ideactions/${ideaction}/proposals/${id}/proposal_ratings`

      const submitBtn = document.getElementById('ratingSubmitBtn')
      submitBtn.setAttribute("disabled", "disabled")
    })
  
    // active validate btn
    const btn = document.getElementById('ratingSubmitBtn')
    const relevance = document.getElementById('proposal_rating_relevance')
    const forecast = document.getElementById('proposal_rating_forecast')
    const maturity = document.getElementById('proposal_rating_maturity')

    relevance.addEventListener('change', () => {
      check_btn(relevance, forecast, maturity, btn)
    })
    forecast.addEventListener('change', () => {
      check_btn(relevance, forecast, maturity, btn)
    })
    maturity.addEventListener('change', () => {
      check_btn(relevance, forecast, maturity, btn)
    })
  }
}

const check_btn = (relevance, forecast, maturity, btn) => {
  if (relevance.value != "" && forecast.value != "" && maturity.value != ""){
    btn.removeAttribute("disabled", "disabled")
  }else {
    btn.setAttribute("disabled", "disabled")
  }
}

export {ratingProposalModal}