const checkEmail = () => {
  const input = document.getElementById('user_email')
  const firstNameInput = document.getElementById('user_first_name')
  const lastNameInput = document.getElementById('user_last_name')
  const check = document.getElementById('check_email')
  if (input && firstNameInput && lastNameInput && check){
    fetch(`/api/internal/users`, { headers: { accept: 'application/json' } })
      .then(response => response.json())
      .then((data) => {
        input.addEventListener("keyup", ()=> {
          const id = testEmail(input.value ,data)
          if (id) {
            fetch(`/api/internal/users/${id}`, { headers: { accept: 'application/json' } })
            .then(response => response.json())
            .then((data) => {
              firstNameInput.value = data['first_name']
              lastNameInput.value = data['last_name']
              firstNameInput.classList.add("form-control-plaintext")
              lastNameInput.classList.add("form-control-plaintext")
              firstNameInput.setAttribute("readonly","readonly");
              lastNameInput.setAttribute("readonly","readonly");
            })
          } else {
            firstNameInput.value = ""
            lastNameInput.value = ""
            firstNameInput.classList.remove("form-control-plaintext")
            lastNameInput.classList.remove("form-control-plaintext")
            firstNameInput.removeAttribute("readonly");
            lastNameInput.removeAttribute("readonly");
          }
        })
      })
  }
}

const testEmail = (email, emails) => {
  for (let i = 0; i < emails.length; i++) {
    const element = emails[i];
    if (element['email'] == email) {
      return element['id']
    }
  }
  return false
}

export{checkEmail}