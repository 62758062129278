const delBtn = (name) => {
  const button = document.getElementById('btnDelIdeaction')
  const input = document.getElementById('nameIdeaction')
  input.addEventListener("keyup", ()=> {
    if (name == input.value) {
      button.classList.remove("disabled")
    } else {
      button.classList.add("disabled")
    }
  })
};


const destroyIdeactionModal = () => {
  $('#destroyIdeactionModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var organizationId = button.data('organizationId')
    var ideactionId = button.data('ideactionId')
    var ideactionName = button.data('ideactionName')
    var modal = $(this)
    modal.find('#modal-text-content').text(ideactionName)
    modal.find('#nameIdeaction').data('name',ideactionName)
    modal.find('#btnDelIdeaction').attr("href", "/organizations/" + organizationId + "/ideactions/" + ideactionId)
    delBtn(ideactionName)
  })
};

export{destroyIdeactionModal}
