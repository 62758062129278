const photoPreview = () => {
  const inputPhoto = document.querySelector('#picture-input')
  if (inputPhoto){
    inputPhoto.addEventListener("change", () => {
      readURL(inputPhoto)
    })
  }
}

function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    
    reader.onload = function(e) {
      $('#picture').attr('src', e.target.result);
    }
    
    reader.readAsDataURL(input.files[0]); // convert to base64 string
  }
}

export{photoPreview}