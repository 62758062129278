import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "purposes", "targets", "proposal", "proposalList", "formFilters" ]
  initialize() {
    this.purposeFilter = this.element.dataset.purposeId
    this.targetFilter = this.element.dataset.targetId
    if (this.controllerName == "ideactions/validations"){
      this.controllerName = "validations"
    }
  }

  // event for btn purpose click
  purpose(event) {
    if (event.target.classList.contains("active")){
      this.purposeFilter = 0
    } else {
      this.purposeFilter = event.target.dataset.id
    }
    this.purposeBtn(this.purposesTargets)
  }
  // event for btn target click
  target(event) {
    if (event.target.classList.contains("active")){
      this.targetFilter = 0
    } else {
      this.targetFilter = event.target.dataset.id
    }
    this.targetBtn(this.targetsTargets)
  }

  selectPurpose(event) {
    this.purposeFilter = event.target.options[event.target.selectedIndex].value
    history.pushState({pageID: 'Ideaction'}, 'Ideaction', `?page=index&purpose=${this.purposeFilter}&target=${this.targetFilter}&page=index`);
    Rails.fire(this.formFiltersTarget, 'submit');
  }
  selectTarget(event) {
    this.targetFilter = event.target.options[event.target.selectedIndex].value
    history.pushState({pageID: 'Ideaction'}, 'Ideaction', `?page=index&purpose=${this.purposeFilter}&target=${this.targetFilter}&page=index`);
    Rails.fire(this.formFiltersTarget, 'submit');
  }

  // change status btn purpose
  purposeBtn(purposes){
    purposes.forEach(purpose => {
      this.new_url(purpose)
      if (purpose.dataset.id == this.purposeFilter){
        purpose.classList.add("active")
      } else {
        purpose.classList.remove("active")
      }
    });
  }

  // change status btn target
  targetBtn(targets){
    targets.forEach(target => {
      this.new_url(target)
      if (target.dataset.id == this.targetFilter){
        target.classList.add("active")
      } else {
        target.classList.remove("active")
      }
    });
  }

  // change url btn and browser
  new_url(target){
    target.href = `?purpose=${this.purposeFilter}&target=${this.targetFilter}`
    history.pushState({pageID: 'Ideaction'}, 'Ideaction', `?page=index&purpose=${this.purposeFilter}&target=${this.targetFilter}`);
  }
}