const proposalWarningProposalMosal = () => {
  const modal = document.getElementById('proposalWarningModal')
  if (modal) {
    const btn = document.getElementById('proposal_warning_submit')
    const input = document.getElementById('proposal_warning_description')
    input.addEventListener('keyup',()=>{
      if (input.textLength != 0){
        btn.removeAttribute("disabled", "disabled")
      } else {
        btn.setAttribute("disabled", "disabled")
      }
    })
  }
}

export {proposalWarningProposalMosal}