const openShowRule = () => {
  const div = document.getElementById('show_rule')
  if (div) {
    $('#ruleModal').modal('show');

  }
  $('#ruleModal').on('hidden.bs.modal', function (e) {
    var video = $(this).find('video')[0];
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
  });
  $('#ruleModal').on('shown.bs.modal', function (e) {
    var video = $(this).find('video')[0];
    if (video) {
      video.play();
      video.currentTime = 0;
    }
  });
}

export { openShowRule }