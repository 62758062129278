const modalEditRegrouping = () => {
  $('#editRegroupingFormModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var regrouping = button.data('regrouping')
    var regroupingName = button.data('regroupingName')
    var regroupingReferent = button.data('regroupingReferent')
    var organization_id = button.data('organization')
    var ideaction_id = button.data('ideaction')
    var alertSetReferent = button.data('alertSetReferent')
    var alertUnsetReferent = button.data('alertUnsetReferent')
    var alertRemoveUser = button.data('alertRemoveUser')

    // update link for delete team regrouping
    var deleteLink = document.getElementById("edit-regrouping-destroy")
    deleteLink.href = `/organizations/${organization_id}/ideactions/${ideaction_id}/regroupings/${regrouping}`

    // udpate title modal
    setTitle(regroupingName, regrouping)

    // reset display 
    resetEdit(regroupingReferent);

    // get list users
    getRegrouping(regrouping, organization_id, ideaction_id, alertSetReferent, alertUnsetReferent, alertRemoveUser);

    // update form
    setRegroupingForm(regrouping, regroupingName, organization_id, ideaction_id);
  })
  const title = document.getElementById('editRegroupingFormModalTitle')
  if (title) {
    title.addEventListener('click', () => {
      document.getElementById('editRegroupingForm').style.display = "unset"
      document.getElementById('editRegroupingFormModalTitle').style.display = "none"
    })
  }
  const cancel = document.getElementById('team-tag-edit-cancel')
  if (cancel) {
    cancel.addEventListener('click', () => {
      document.getElementById('editRegroupingForm').style.display = "none"
      document.getElementById('editRegroupingFormModalTitle').style.display = "unset"
    })
  }

  // auto open modal regrouping
  const autoOpen = document.getElementById("autoOpenRegrouping")
  if (autoOpen) {
    document.getElementById(autoOpen.dataset.id).click()
  }
}

const resetEdit = (regroupingReferent) => {
  document.getElementById('editRegroupingForm').style.display = "none"
  document.getElementById('editRegroupingFormModalTitle').style.display = "unset"
  document.getElementById('edit-regrouping-users').innerHTML = '<div class="d-flex justify-content-center"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>'
  if (regroupingReferent == true) {
    document.getElementById('editRegroupingFormModalSubTitle').style.display = "none";
  } else {
    document.getElementById('editRegroupingFormModalSubTitle').style.display = "unset"
  }
}

const setRegroupingForm = (regrouping, regroupingName, organization_id, ideaction_id) => {
  const input = document.getElementById('edit_regrouping_name')
  input.value = regroupingName;
  const form = document.getElementById('edit_regrouping')
  form.action = `/organizations/${organization_id}/ideactions/${ideaction_id}/regroupings/${regrouping}`
}

const getRegrouping = (regrouping, organization_id, ideaction_id, alertSetReferent, alertUnsetReferent, alertRemoveUser) => {
  fetch(`/organizations/${organization_id}/ideactions/${ideaction_id}/regroupings/${regrouping}`, { headers: { accept: 'application/json' } })
    .then(response => response.json())
    .then((data) => {
      if (data.regrouping.referent) {
        setUsers(data.users, organization_id, ideaction_id, regrouping, data.regrouping.referent.id, alertSetReferent, alertUnsetReferent, alertRemoveUser)
      } else {
        setUsers(data.users, organization_id, ideaction_id, regrouping, null, alertSetReferent, alertUnsetReferent, alertRemoveUser)
      }
    });
}

const setTitle = (regrouping_name, regrouping) => {
  const title = document.getElementById('editRegroupingFormModalTitle')
  title.innerHTML = regrouping_name
  document.getElementById("edit_regrouping_name").value = regrouping
  document.getElementById("form_regrouping_id").value = regrouping
  document.getElementById("form_tag_regrouping_id").value = regrouping
}

const setUsers = (users, organization_id, ideaction_id, regrouping_id, referent_id, alertSetReferent, alertUnsetReferent, alertRemoveUser) => {
  const html_users = document.getElementById('edit-regrouping-users')
  html_users.innerHTML = ""
  if (users.length == 0) {
    html_users.insertAdjacentHTML('beforeend', `<div>Aucun idéacteur</div>`);
  }
  users.forEach(user => {
    let content = `<div id="membership-${user.ideaction_membership_id}" class="edit-team-tag-user">`
    content += `<div>`
    if (referent_id == user.ideaction_membership_id) {
      content += `<i class="fa-solid fa-star referent"></i>`
    }
    content += `${user.full_name}`
    content += `</div>`
    content += `<div class="cta">`
    if (referent_id != user.ideaction_membership_id) {
      content += `<a class="setref" href="/organizations/${organization_id}/ideactions/${ideaction_id}/regroupings/${regrouping_id}/set_referent_user?membership=${user.ideaction_membership_id}" data-method="post" data-confirm="${alertSetReferent}"><i class="fa-solid fa-star"></i></a>`
    } else {
      content += `<a class="unsetref" href="/organizations/${organization_id}/ideactions/${ideaction_id}/regroupings/${regrouping_id}/unset_referent_user" data-method="post" data-confirm="${alertUnsetReferent}"><i class="fa-regular fa-star"></i></a>`
    }
    content += `<a class="delete" href="/organizations/${organization_id}/ideactions/${ideaction_id}/regroupings/${regrouping_id}/remove_user?membership=${user.ideaction_membership_id}" data-method="delete" data-confirm="${alertRemoveUser}"><i class="fa-solid fa-minus"></i></a>`
    content += `</div>`
    content += `</div>`
    html_users.insertAdjacentHTML('beforeend', content);
  });


}


export { modalEditRegrouping }