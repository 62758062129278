const pilotForm = () => {
  const button = document.getElementById("btn-step-pilot")
  const input = document.getElementById("proposal_pilot");
  if (input){
    input.addEventListener("change", ()=> {
      if (input.value.length != 0) {
        if(button.attributes.getNamedItem("disabled") != null){
          button.attributes.removeNamedItem("disabled")
        }
      } else {
        button.setAttribute("disabled", "disabled")
      }
    })
  };
}

const planForm = () => {
  const button = document.getElementById("btn-step-plan")
  const input = document.getElementById("proposal_result_expected");
  const input2 = document.getElementById("proposal_result_for");
  if (input && input2){
    input.addEventListener("keyup", ()=> {
      if (input.value.length != 0 && input2.value.length != 0) {
        if(button.attributes.getNamedItem("disabled") != null){
          button.attributes.removeNamedItem("disabled")
        }
      } else {
        button.setAttribute("disabled", "disabled")
      }
    })
    input2.addEventListener("change", ()=> {
      if (input.value.length != 0 && input2.value.length != 0) {
        if(button.attributes.getNamedItem("disabled") != null){
          button.attributes.removeNamedItem("disabled")
        }
      } else {
        button.setAttribute("disabled", "disabled")
      }
    })
  };
}

const affectFrom =  () => {
  const button = document.getElementById("btn-step-start")
  const input = document.getElementById("proposal_started_at")
  if (input){
    input.addEventListener('change', () => {
      if (input.value.length != 0) {
        if(button.attributes.getNamedItem("disabled") != null){
          button.attributes.removeNamedItem("disabled")
        }
      } else {
        button.setAttribute("disabled", "disabled")
      }
    })
  }
}

const evaluatedForm = () =>{
  var slider = document.getElementById("proposal_evaluated_result");
  var output = document.getElementById("valueRange");
  if (slider){
    output.innerHTML = slider.value; // Display the default slider value

    // Update the current slider value (each time you drag the slider handle)
    slider.oninput = function() {
      output.innerHTML = this.value;
    }
  }
}

const evaluatedDirectForm = () => {
  const button = document.getElementById("btn-step-evaluat")
  const input = document.getElementById("proposal_result_expected")
  if (button) {
    input.addEventListener("keyup", ()=> {
      if (input.value.length != 0) {
        if(button.attributes.getNamedItem("disabled") != null){
          button.attributes.removeNamedItem("disabled")
        }
      } else {
        button.setAttribute("disabled", "disabled")
      }
    })
  }
}

const addMilestone = () => {
  const addBtn = document.getElementById("add-milestone-form")
  const content = document.getElementById("step-milestone")
  const contentMsg = document.getElementById("step-milestone-msg")
  const msgLimit = `<small class="text-danger">Limite à 3 jalons</small>`
  if (addBtn) {
    let counter = parseInt(content.dataset.nbr, 10) + 1
    addBtn.addEventListener("click", (event) => {
      event.preventDefault();
      if (counter < 4) {
        const form = formContent(counter)
        content.insertAdjacentHTML("beforeend", form);
        initializeFlatPicker(counter)
        counter++
      }else{
        contentMsg.innerHTML = msgLimit
      }
    })
  }
}

const formContent = (counter) => {
  const form = `<div class="col-md-8">
                  <div class="form-group">
                    <input class="form-control" name="proposal[milestone${counter}]" id="proposal_jalon${counter}" placeholder="Jalon ${counter}">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input class="form-control string optional datepicker${counter} flatpickr-input" placeholder="Choisir une date" type="text" name="proposal[time${counter}]" id="proposal_time${counter}" readonly="readonly">
                  </div>
                </div>`;
  return form
}


function initializeFlatPicker (counter) {
  let startDate = document.getElementById('proposal_started_at').value.split("-").reverse().join('-');
  if (startDate == ""){
    startDate = null
  }
  const evaluatedDate = document.getElementById('proposal_started_at').dataset.evaluated;
  const weekshort = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  const weeklong = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const monthshort = ['Jan', 'Fév', 'Мar', 'Аvr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'];
  const monthlong = ['Janvier', 'Février', 'Mars', 'Аvril', 'Мai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Оctobre', 'Novembre', 'Décembre'];
  const element = ".datepicker"+counter
  flatpickr(element, {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: weekshort,
        longhand: weeklong,         
      }, 
      months: {
        shorthand: monthshort,
        longhand: monthlong,
      }
    },
    minDate: startDate,
    maxDate: evaluatedDate,
    dateFormat: "d-m-Y"
  });
}
export{pilotForm, planForm, affectFrom,evaluatedForm, addMilestone, evaluatedDirectForm}