const proposalTagCell = () => {
  const cells = document.querySelectorAll('.proposal-tag-toggle-modal')
  cells.forEach(cell => {
    cell.addEventListener('click',(e)=>{
      e.preventDefault();
      resetDataTable();
      closeTagModal();
      const proposalId = cell.dataset.proposalId
      const organizationId = cell.dataset.organizationId
      const ideactionId = cell.dataset.ideactionId
      addDeleteOnTag(proposalId);
      // show modal
      const proposalModal = document.getElementById(`proposal-tag-modal-${proposalId}`)
      proposalModal.classList.toggle('active')

      // get ideaction tags
      const lists = document.getElementById(`proposal-tag-lists-${proposalId}`)
      lists.innerHTML = ''
      fetch(`/organizations/${organizationId}/ideactions/${ideactionId}/tags.json`, { headers: { accept: 'application/json' } })
        .then(response => response.json())
        .then((data) => {
          if (data.length == 0) {
            lists.insertAdjacentHTML('beforeend', '<p id="no-ideaction-tag">Aucun tag de disponible, il faut les créer!</p>');
          } else {
            data.forEach(e => {
              lists.insertAdjacentHTML('beforeend', tag_list(e.tag_id, e.tag_name, e.tag_typology, e.organization_id, e.ideaction_id, proposalId));
            });
          }
        })
        .then(() => {
          toggle_tag_menu()
        })
        .then(() => {
          edit_tag(organizationId, ideactionId)
        })
    })
  })

}

const closeTagModal = () => {
  const closeTagModals = document.querySelectorAll(".proposal-tag-close-modals")
  closeTagModals.forEach(closeBtn => {
    closeBtn.addEventListener('click', (btn)=>{
      btn.preventDefault()
      resetDataTable();
    })
  })
}

const addDeleteOnTag = (proposalId) => {
  const tags = document.querySelectorAll(`.proposal-tags-${proposalId}`)
  tags.forEach(tag =>{
    const proposalTagId = tag.dataset.proposalTagId
    tag.insertAdjacentHTML('beforeend', `<a class="ml-2 proposal-tag-delete" data-method="delete" data-remote="true" href="/proposal_tags/${proposalTagId}"><i class="fa-solid fa-times"></i></a>`)
  })
}

const resetDataTable = () => {
  // remove delete on tag
  const proposalTagDelete = document.querySelectorAll('.proposal-tag-delete')
  proposalTagDelete.forEach(d => {
    d.remove()
  })

  // remove all tag
  const proposalTagLists = document.querySelectorAll('.proposal-tag-lists')
  proposalTagLists.forEach(tagList => {
    tagList.innerHTML = ""
  })

  // hide all add tag modal
  const proposalTagModals = document.querySelectorAll('.proposal-tag-modals')
  proposalTagModals.forEach(modal => {
    modal.classList.remove('active')
  })

  // reset DataTable
  $('#table-datatables').DataTable().destroy()
  $('#table-datatables').DataTable(
    {
      "stateSave": true, // add for turbolinks
      "responsive": true,
      "language": {
        "decimal":        "",
        "emptyTable":     "Pas de data",
        "info":           "Afficher _START_ à _END_ sur _TOTAL_ lignes",
        "infoEmpty":      "Afficher 0 à 0 sur 0 ligne",
        "infoFiltered":   "(filtré de _MAX_ lignes au total)",
        "infoPostFix":    "",
        "thousands":      "",
        "lengthMenu":     "_MENU_",
        "loadingRecords": '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
        "processing":     "Traitement...",
        "search":         "",
        "zeroRecords":    "Aucun résultat correspondants trouvés",
        "paginate": {
          "first":      "First",
          "last":       "Last",
          "next":       "Suivant",
          "previous":   "Précédent"
        }
      }
    }
  );
}

const tag_list = (tag_id, tag_name, tag_typology, organization_id, ideaction_id, proposal_id) => {
  let result = ``
  if(tag_typology == "purpose"){
    result += `<span id="tag_${tag_id}" class="proposal-tag purpose-tag">`
  } else if( tag_typology == "target"){
    result += `<span id="tag_${tag_id}" class="proposal-tag target-tag">`
  } else {
    result += `<span id="tag_${tag_id}" class="proposal-tag">`
  }
  result += `<a id="tag-name-${tag_id}" data-method="post" data-remote="true" href="/organizations/${organization_id}/ideactions/${ideaction_id}/proposals/${proposal_id}/proposal_tags?tag_id=${tag_id}">${tag_name}`
  result += `<a class="ml-2 tag-menu-toggle" data-tag-id="${tag_id}" href=""><i class="fa-solid fa-ellipsis-v"></i></a>`
  result += `<span id="tag-menu-${tag_id}" class="tag-menu">`
  if (tag_typology == "purpose" || tag_typology == 'target') {
    result += `<a class="ml-2" data-method="delete" data-remote="true" data-confirm="Supprimer le tag de l'ensemble des propositions?" href="/organizations/${organization_id}/ideactions/${ideaction_id}/tags/${tag_id}"><i class="fa-solid fa-times"></i></a>`
  }else {
    result += `<a class="ml-2 tag-menu-edit" data-tag-id="${tag_id}" href=""><i class="fa-solid fa-pen"></i></a>`
    result += `<a class="ml-2" data-method="delete" data-remote="true" data-confirm="Supprimer le tag de l'ensemble des propositions?" href="/organizations/${organization_id}/ideactions/${ideaction_id}/tags/${tag_id}"><i class="fa-solid fa-trash-alt"></i></a>`
  }
  result += `</span>`
  result += `</a></span>`
  return result
}

const toggle_tag_menu = () => {
  const tags = document.querySelectorAll('.tag-menu-toggle')
  tags.forEach(element => {
    element.addEventListener('click', (e)=>{
      e.preventDefault()
      const tagId = element.dataset.tagId
      document.getElementById(`tag-menu-${tagId}`).classList.toggle('active')
    })
  });
}

const edit_tag = (organization_id, ideaction_id) => {
  const edit_tags = document.querySelectorAll('.tag-menu-edit')
  edit_tags.forEach(element => {
    element.addEventListener('click',(e) => {
      e.preventDefault()
      const tagId = element.dataset.tagId
      const nameLink = document.getElementById(`tag-name-${tagId}`)
      const name = nameLink.innerText
      nameLink.remove();
      const tag = document.getElementById(`tag_${tagId}`)
      tag.insertAdjacentHTML('afterbegin', form_edit_tag(organization_id, ideaction_id, tagId, name));
    })
  })
}

const form_edit_tag = (organization_id, ideaction_id, tag_id, tag_name) => {
  let result = ''
  result += `<form method="POST" action="/organizations/${organization_id}/ideactions/${ideaction_id}/tags/${tag_id}"" data-confirm="Modifier le tag sur l'ensemble des propositions?">`
  result += '<input name="_method" type="hidden" value="PUT">'
  result += `<input name="name" type="text" placeholder="Nom du tag" value="${tag_name}"></input>`
  result += '</form>'
  return result
}

export {proposalTagCell}