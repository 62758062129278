const regroupingAddUsersModal = () => {
  const submitUser = document.getElementById('submit_invite_users');
  const inputUser = document.querySelector("#add_users_regrouping ul");

  if (inputUser) {
    const observer = new MutationObserver(() => {
      const nbr = document.querySelectorAll("#add_users_regrouping .select2-selection__choice").length;
      if (nbr === 0) {
        submitUser.setAttribute("disabled", "disabled");
      } else {
        submitUser.removeAttribute("disabled");
      }
    });

    // Observe les changements dans les enfants et sous-arbres de l'élément
    observer.observe(inputUser, { childList: true, subtree: true });
  }
}

export { regroupingAddUsersModal }
