const editTemplateCategory = () => {
  $('#editTemplateCategoryModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('id')
    var name = button.data('name')
    document.getElementById('editTemplateCategoryModalTitle').innerText = name
    getTemplate(id)
  })
}

const getTemplate = (id) => {
  fetch(`/template_categories/${id}`, { headers: { accept: 'application/json' } })
        .then(response => response.json())
        .then((data) => {
          setTemplate(data.template_category)
        });
}

const setTemplate = (template_category) => {
  document.querySelector('.edit_template_category #template_category_icon').value = template_category.icon
  document.querySelector('.edit_template_category #template_category_name').value = template_category.name
  document.querySelector('.edit_template_category').action = `/template_categories/${template_category.id}`

}

export {editTemplateCategory}