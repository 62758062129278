const regroupingRelaunchModal = () => {
  $('#relaunchRegroupingModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var title = button.data('title')
    var organization = button.data('organization')
    var ideaction = button.data('ideaction')
    var regrouping = button.data('regrouping')
    var size = button.data('size')
    var modal = $(this)
    var referent = button.data('referent')
    var titleModalText = button.data('titleModal')
    document.querySelector("#regroupingName").innerHTML = title
    const titleModal = document.getElementById("relaunchRegroupingModalTitle")
    titleModal.innerHTML = titleModalText
    if (referent) {
      $("#regroupingRelaunchSubmit").attr('data-confirm', size);
      modal.find('#edit_regrouping').attr("action", "/organizations/" + organization + "/ideactions/" + ideaction + "/regroupings/" + regrouping + "/relaunch_regrouping_referent")
    } else {
      $("#regroupingRelaunchSubmit").attr('data-confirm', size);
      modal.find('#edit_regrouping').attr("action", "/organizations/" + organization + "/ideactions/" + ideaction + "/regroupings/" + regrouping + "/relaunch_regrouping")
    }
  })

  const input = document.getElementById('regroupingContent')
  const btn = document.getElementById('regroupingRelaunchSubmit')
  if (btn) {
    input.addEventListener("keyup", () => {
      if (input.value.length != 0) {
        if (btn.attributes.getNamedItem("disabled") != null) {
          btn.attributes.removeNamedItem("disabled")
        }
      } else {
        btn.setAttribute("disabled", "disabled")
      }
    })
  }
}

export { regroupingRelaunchModal }