const unsupportReferent = () => {
  const btn = document.getElementById('btnUnsupportReferent')
  if (btn) {
    btn.addEventListener('click', () => {
      $('#unsupportReferentsModal').modal('hide');
      setTimeout(() => {$('#validateProposalModal').modal('show');}, 500);
    })
  }
}

export { unsupportReferent}