const trixInit = () => {
  // add place holder for add url link
  addEventListener("trix-initialize", event => {
    const { toolbarElement } = event.target
    const inputElement = toolbarElement.querySelector("input[name=href]")
    inputElement.setAttribute("placeholder", event.target.dataset.urllink)
    inputElement.type = "text"
    inputElement.pattern = "(https?://|/).+"
  })

  // add blank target for url in trix content
  const content = document.querySelector('.content-trix')
  if (content) {
    const contents = document.querySelectorAll('.content-trix')
    for (let index = 0; index < contents.length; index++) {
      const element = contents[index];
      const links = element.querySelectorAll('a')
      for (let index = 0; index < links.length; index++) {
        const link = links[index];
        if (!link.classList.contains("trix_not_blank")){
          link.setAttribute('target', '_blank')
        }
      }
    }
  }
}

export {trixInit} 
