const updateRoleIdeactionModal = () => {
  $('#roleUserModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('id')
    var role = button.data('role')
    var roleId = button.data('roleId')
    var referent = button.data('referent')
    var fullName = button.data('fullname')
    var modal = $(this)
    modal.find('#update_ideaction_membership').attr("action", "/ideaction_memberships/" + id)
    modal.find('#ideactionMembershipRole option').prop('selected', false)
    modal.find(`#${role}`).prop('selected', true)
    document.getElementById('roleUserModalFullName').innerHTML = fullName
    initListRule();
    initToggleReferent(referent);
    selectListRule(parseInt(roleId, 10));
  })
  const inputSelect = document.getElementById('ideactionMembershipRole')
  if (inputSelect) {
    inputSelect.addEventListener('change', () => {
      initListRule();
      const optRole = inputSelect.options[inputSelect.selectedIndex]
      selectListRule(parseInt(optRole.value, 10));
    })
  }
};
const initListRule = () => {
  const listRules = document.querySelectorAll('.listRule')
  listRules.forEach(listRule => {
    listRule.classList.add("not_select")
    listRule.querySelector("i").classList.remove("fa-circle-check")
    listRule.querySelector("i").classList.add("fa-circle-xmark")
  });
  if (document.getElementById('alert-no-supervisor')) {
    document.getElementById('alert-no-supervisor').style.display = "none"
  }
}

const initToggleReferent = (referent) => {
  const input = document.getElementById('ideactionmembership_referent')
  const input_value = document.getElementById('ideactionmembership_referent_value')
  if (referent) {
    input_value.value = 1
    input.setAttribute('checked', 'checked')
  } else {
    input_value.value = 0
    input.removeAttribute('checked', 'checked')
  }
}

const selectListRule = (element) => {
  switch (element) {
    case 0:
      addListRule(document.querySelectorAll('.listRule1'));
      break;
    case 1:
      addListRule(document.querySelectorAll('.listRule1'));
      break;
    case 2:
      addListRule(document.querySelectorAll('.listRule1'));
      addListRule(document.querySelectorAll('.listRule2'));
      break;
    case 3:
      addListRule(document.querySelectorAll('.listRule1'));
      addListRule(document.querySelectorAll('.listRule2'));
      addListRule(document.querySelectorAll('.listRule3'));
      break;
    case 4:
      addListRule(document.querySelectorAll('.listRule1'));
      addListRule(document.querySelectorAll('.listRule2'));
      addListRule(document.querySelectorAll('.listRule3'));
      addListRule(document.querySelectorAll('.listRule4'));
      break;
    case 5:
      addListRule(document.querySelectorAll('.listRule1'));
      addListRule(document.querySelectorAll('.listRule2'));
      addListRule(document.querySelectorAll('.listRule3'));
      addListRule(document.querySelectorAll('.listRule4'));
      addListRule(document.querySelectorAll('.listRule5'));
      if (document.getElementById('alert-no-supervisor')) {
        document.getElementById('alert-no-supervisor').style.display = "block"
      }
      break;
  }
}

const addListRule = (elements) => {
  elements.forEach(element => {
    element.classList.remove("not_select")
    element.querySelector("i").classList.add("fa-circle-check")
    element.querySelector("i").classList.remove("fa-circle-xmark")
  });
}

export { updateRoleIdeactionModal }