const addTeamUserModal = () => {
  $('#teamUserUpdateModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id = button.data('id')
    var userFullname = button.data('userFullname')
    var teamId = button.data('teamId')
    var modal = $(this)
    modal.find('#update_organization_membership_team').attr("action", "/organization_memberships/" + id + "/update_team")
    modal.find('#selectTeam option').prop('selected', false)
    modal.find('#teamUserUpdateModalTitle').text(userFullname)
    modal.find(`#${teamId}`).prop('selected', true)
  })
};

export{addTeamUserModal}