const proposalWarnings = () => {
  $('#proposalWarningsShowModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal

    // get data
    var title = button.data('title')
    var organization = button.data('organization')
    var ideaction = button.data('ideaction')
    var proposal = button.data('proposal')

    // change name modal
    var modal = $(this)
    modal.find('#proposalWarningName').text(title)

    // get warning
    const content = document.getElementById('proposalWarningsShowContent')
    content.innerHTML = ''
    fetch(`/organizations/${organization}/ideactions/${ideaction}/proposals/${proposal}/proposal_warnings.json`, { headers: { accept: 'application/json' } })
      .then(response => response.json())
      .then((data) => {
        data.forEach(e => {
          content.insertAdjacentHTML('beforeend', card(e.user, e.email, e.description, e.created_at, e.referent));
        });
      })
  })
}

const card = (user, email, description, date, referent) => {
  if (referent) {
    return `<div class="proposal-warning">
      <div class="warning-title">
        <i class="fa-solid fa-crown mr-1 text-warning" title="référent"></i>
        ${user} -
        <a title="Envoyez un email à Ideactor Ideactor" href="mailto:${email}">
          <i class="fa-solid fa-envelope"></i>
        </a>
      </div>
      <div class="warning-description">
        ${description}
      </div>
      <div class="warning-footer">
        <i>${date}</i>
      </div>
    </div>`
  } else {
    return `<div class="proposal-warning">
      <div class="warning-title">
        ${user} -
        <a title="Envoyez un email à Ideactor Ideactor" href="mailto:${email}">
          <i class="fa-solid fa-envelope"></i>
        </a>
      </div>
      <div class="warning-description">
        ${description}
      </div>
      <div class="warning-footer">
        <i>${date}</i>
      </div>
    </div>`
  }
  
}

export {proposalWarnings}
