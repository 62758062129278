import Typed from 'typed.js'

const typedJsBanner = () => {
  if (document.getElementById('banner-typed-text')) {
    new Typed('#banner-typed-text', {
      strings: [ "au bureau", "à la maison", "sur le terrain", " au bureau"],
      typeSpeed: 80,
      loop: true
    });
  };
}

export {typedJsBanner}