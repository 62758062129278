const popupNewProposal = () => {
  const popupModal = document.getElementById('popupNewProposalModal')
  if (popupModal) {
    $(function() {
      $("#popupNewProposalModal").modal('show');
      // setTimeout(function() {$('#popupNewProposalModal').modal('hide');}, 4000);
    });
  }
}

export{ popupNewProposal}