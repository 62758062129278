const usersRelaunchModal = () => {
  $('#relaunchUserModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var title = button.data('title')
    var size = button.data('size')
    var users = button.data('users')
    var info = button.data('info')
    if (title) {

      document.querySelector("#usersName").innerHTML = title
    }
    $("#usersRelaunchSubmit").attr('data-confirm', size);
    if (users){
      document.getElementById('users_type').value = users
    }
    if (info) {
      document.getElementById('infoUser').innerHTML = info
    } else {
      document.getElementById('infoUser').innerHTML = ''
    }
  })

  
  const input = document.getElementById('usersContent')
  const btn = document.getElementById('usersRelaunchSubmit')
  if (btn){
    input.addEventListener("keyup", ()=> {
      if (input.value.length != 0) {
        if(btn.attributes.getNamedItem("disabled") != null){
          btn.attributes.removeNamedItem("disabled")
        }
      } else {
        btn.setAttribute("disabled", "disabled")
      }
    })
  }

  const openModal = document.getElementById('relaunchUserModal_open')
  if (openModal) {
    setTimeout(function() {$('#relaunchUserModal').modal('show');}, 500);
  }
}

export {usersRelaunchModal}