const riskCorrectionToggle = () => {
  const openRisk = document.getElementById('open_risk')
  if (openRisk) {
    const openRickSwitch = document.querySelector('#open_risk label')
    openRickSwitch.addEventListener("click",()=>{
      const riskCorrection = document.getElementById('open_correction_risk')
      riskCorrection.classList.toggle('disabled')
    })
  }
}

export {riskCorrectionToggle}