const templateModal = () => {
  const templateNextBtn = document.getElementById("template_next")
  
  
  $('#templateModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    const modalTemplate = document.getElementById('templateModal')
    removeIdCancelBtn(modalTemplate);
    if (!modalTemplate.dataset.id) {
      modalTemplate.dataset.id = button.data('id')
      modalTemplate.dataset.name = button.data('name')
    }
    document.getElementById('templateModalTitle').innerText = modalTemplate.dataset.name
    templateNextBtn.classList.add("disabled")
    getTemplates(modalTemplate.dataset.id)
    setTimeout(() => {unlockBtnTemplate(templateNextBtn)}, 500)
  })
}

// get all templates for this template category
const getTemplates = (id) => {
  const listTemplates = document.getElementById('listTemplates')
  listTemplates.innerHTML = ''
  document.getElementById("templateDescription").innerHTML = ""
  fetch(`/template_categories/${id}`, { headers: { accept: 'application/json' } })
        .then(response => response.json())
        .then((data) => {
          data.templates.forEach(element => {
            listTemplates.insertAdjacentHTML('beforeend', setTemplate(element))
          });
        });
}

const getTemplateDescription = (id) => {
  fetch(`/templates/${id}`, { headers: { accept: 'application/json' } })
        .then(response => response.json())
        .then((data) => {
          document.getElementById("templateDescription").innerHTML = ""
          document.getElementById("templateDescription").innerHTML = data.template_description
        });
}

// create template card for radio form
const setTemplate = (template) => {
  const contentHTML = `<div class="col-lg-4 text-center">
                        <label class="form-imagecheck mb-2" data-children-count="1" data-template-id="${template.id}" data-template-name="${template.name}" data-template-goal="${template.goal}" data-template-picture="${template.picture}">
                          <input name="form-imagecheck" type="radio" value="${template.id}" class="form-imagecheck-input">
                          <span class="form-imagecheck-figure">
                            <div class="form-imagecheck-image" style="background-image: url('${template.picture}')"></div>
                            <div class="goal">${template.goal}<span>
                          </span>
                        </label>
                      </div>`
  return contentHTML
}

// remove id template category when cancel modal
const removeIdCancelBtn = (modalTemplate) => {
  const templateCancel = document.getElementById("template_cancel")
  templateCancel.addEventListener('click', ()=>{
    delete modalTemplate.dataset.id;
  })
}

// unlock next bettom after select template
const unlockBtnTemplate = (nextBtn) => {
  const inputsTemplate = document.querySelectorAll('.form-imagecheck')
  inputsTemplate.forEach(element => {
    element.addEventListener('change', ()=>{
      nextBtn.classList.remove("disabled")
      nextBtn.dataset.templateId = element.dataset.templateId
      nextBtn.dataset.templateGoal = element.dataset.templateGoal
      nextBtn.dataset.templateName = element.dataset.templateName
      document.getElementById("ideaction_template").value = element.dataset.templateId
      document.getElementById("logo-preview").src = element.dataset.templatePicture
      getTemplateDescription(element.dataset.templateId)
    })
  });
}


export {templateModal}
