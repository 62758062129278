const teamRelaunchModal = () => {
  $('#relaunchTeamModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var title = button.data('title')
    var organization = button.data('organization')
    var ideaction = button.data('ideaction')
    var team = button.data('team')
    var size = button.data('size')
    var modal = $(this)
    document.querySelector("#teamName").innerHTML = title
    modal.find('#edit_team').attr("action", "/organizations/" + organization + "/ideactions/" + ideaction + "/teams/" + team + "/relaunch_team")
    $("#teamRelaunchSubmit").attr('data-confirm', size);
  })
  
  const input = document.getElementById('teamContent')
  const btn = document.getElementById('teamRelaunchSubmit')
  if (btn){
    input.addEventListener("keyup", ()=> {
      if (input.value.length != 0) {
        if(btn.attributes.getNamedItem("disabled") != null){
          btn.attributes.removeNamedItem("disabled")
        }
      } else {
        btn.setAttribute("disabled", "disabled")
      }
    })
  }
}

export {teamRelaunchModal}