const purposeSelect = () => {
  const purposes = document.querySelectorAll(".proposal_purpose")
  const purposeSelect = document.getElementById("proposal_purpose")
  const purposeTitle = document.getElementById("purpose_title")
  if (purposes) {
    purposes.forEach(purpose => {
      purpose.addEventListener("click", (event) =>{
        $('#purpose_modal').modal('hide');

        if (event.target.dataset.id != "null"){
          purposeSelect.value = event.target.dataset.id;
          if (purposeTitle) {
            purposeTitle.innerText = event.target.dataset.name
          }
        }
        setTimeout(() => {$('#newProposalFormModal').modal('show')}, 450)
      })
    })
  }
}

export {purposeSelect}