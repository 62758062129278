const inviteToAction = () => {
  const inviteAction = document.getElementById('inviteToActionModal')
  if (inviteAction){
    const returnBtn = document.getElementById('returnBTN')
    returnBtn.addEventListener("click",event => {
      const voteOpen = document.querySelector('.open-vote')
      if (voteOpen) {
        event.preventDefault()
        $('#inviteToActionModal').modal('show')
      }
    })
  } 
}

export {inviteToAction}