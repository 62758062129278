const delBtn = (name) => {
  const button = document.getElementById('btnDelOrganization')
  const input = document.getElementById('nameOrganization')
  input.addEventListener("keyup", ()=> {
    if (name == input.value) {
      button.classList.remove("disabled")
    } else {
      button.classList.add("disabled")
    }
  })
};


const destroyOrganizationModal = () => {
  $('#destroyOrganizationModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var organizationId = button.data('organizationId')
    var organizationName = button.data('organizationName')
    var modal = $(this)
    modal.find('#modal-text-content').text(organizationName)
    modal.find('#nameOrganization').data('name',organizationName)
    modal.find('#btnDelOrganization').attr("href", "/organizations/" + organizationId)
    delBtn(organizationName)
  })
};

export{destroyOrganizationModal}
