const importUsers = () => {
  const btn = document.getElementById('import-users-btn')
  const file = document.getElementById('import-users-file')
  const progressBar = document.getElementById('div-progress')
  if (btn) {
    btn.addEventListener('click', () => {
      btn.classList.add("disabled")
      btn.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...'
      progressBar.style.display ="unset"
      setInterval(() => { check_status(btn.dataset.organization, btn.dataset.user)
      }, 2000 );
      setTimeout(() => { btn.setAttribute("disabled", "disabled")}, 200)
    })
    file.addEventListener('change', () => {
      const text = document.getElementById('import-users-file-text')
      const result = file.value.split("\\")
      text.innerHTML= result[result.length - 1]
    })
  }
}

const check_status = (organization, user) => {
  const url = `/record_job_status.json?organization=${organization}&user=${user}&type=organization_membership`
  const bar = document.getElementById('job-progress')
  fetch(url, { headers: { accept: "application/json" }})
      .then(response => response.json())
      .then((data) => {
        if (data.record_job == 0) {
          location.reload();
        } else {
          const progress = (data.total_job - data.record_job) / data.total_job * 100
          bar.style.width = `${progress}%`
        }
        
      });
}

export{importUsers}