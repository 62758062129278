import $ from 'jquery';
import 'select2';

const initSelect2 = () => {
  $('.select2').select2({ 
    width: '100%',
    theme: 'bootstrap4',
    placeholder: $('.select2').attr('placeholder')
  });
};

const initSelect2Multi = () => {
  $('.select2-multi').select2({
    width: '100%',
    theme: 'bootstrap4',
    placeholder: $('.select2-multi').attr('placeholder')
  });
};

export { initSelect2, initSelect2Multi };